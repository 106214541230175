.movement-voyageRemarks {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);

  :global {
    .voyageRemarks-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 12px)/2));
      gap: 12px;
      grid-template-rows: repeat(auto-fit, 177px);

      &>* {
        display: flex;
        flex-direction: column;
        gap: 13px;
        &>:first-child {
          color: var(--color-text-primary, #355691);
          font-size: 16px;
          font-weight: 590;
          line-height: 24px;
          padding-bottom: 8px;
          border-bottom: 1px solid var(--color-line-separator, #E7E9EC);
          /* 150% */
        }
        .voyageRemarks-textarea {
          width: 100%;
          height: 137px;
        }
      }
    }
  }
}

.movement-voyageRemarks-tctc {
  :global {
    .voyageRemarks-container {
      display: flex;
      flex-direction: column;
    }
  }
}