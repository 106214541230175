.movement-task--popover {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 88;
}

.movement-task {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  height: 100%;
  display: flex;
  flex-direction: column;
  :global{
    .movement-task-container {
      width: 100%;
      height: calc(100% - 83px);
      position: relative;
      transition: visibility 425ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      visibility: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      .movement-task-container--mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        backdrop-filter: brightness(70%) blur(14px);
        opacity: 1;
        transition: opacity 335ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      .movement-task-container--details {
        bottom: 10px;
        position: absolute;
        z-index: 2;
        transform: scale(1);
        transition: transform 335ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        height: 90%;
      }
    }
  }
}

.movement-task--visible {
  height: fit-content;
  :global {
    .movement-task-container {
      visibility: hidden;
      .movement-task-container--mask {
        opacity: 0;
      }
      .movement-task-container--details {
        transform: scale(0);
      }
    }
  }
}