.fleet-textarea {
  width: 479px;
  height: 175px;
  position: relative;

  :global {
    .textarea {
      padding: 11px 16px;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 1px solid #D9D9D9;
      // background: rgba(217, 217, 217, 0.08);
      color: rgba(0, 0, 0, 0.25);
      
      font-size: 14px;
      font-weight: 500;
      position: relative;
      textarea {
        padding: 0px;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          display: none;
        }
    
        &::-webkit-scrollbar-corner {
          background-color: rgba(41, 42, 44, 1);
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: rgba(41, 42, 44, 1);
          border: 1px solid rgb(219, 219, 219);
        }
    
        &::-webkit-scrollbar-track {
          border-radius: 4px;
        }
    
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE 10+ */
      }
      &:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: #355691;
      }
      &:not(.ant-input-affix-wrapper-disabled):focus {
        box-shadow:  0px 0px 0px 2px rgba(53, 86, 145, 0.15);
      }

      &.exist-label {
        height: calc(100% - 18px);
      }
      &.exist-icon {
        text-indent: 17px;
      }
      &>.ant-input-suffix {
        .ant-input-clear-icon {
          position: unset;
          // visibility:visible;
        }
      }
    }

    .textarea-label {
      color: #484848;
      // text-align: center;
      
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 4px;
    }

    .textarea-prefix {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 14px;
      left: 12px;
    }

    .textarea-clear {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 16px;
      bottom: 16px;
    }

  }
}