.fleet-routeGraph {
  display: flex;
  width: calc(100% - 411px);
  height: 100%;
  flex-direction: column;
  margin-right: 12px;
  position: relative;

  :global {

    // .routeGraph-header {
    //   width: 100%;
    //   margin-bottom: 16px;
    //   height: 23px;
    // }
    .routeGraph {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 1px solid #D9D9D9;
      overflow: hidden;

      .routeGraph-frame {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        border: 0px;
      }

      .coordinates-container {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        font-weight: 2000;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px;
        font-size: 6px;
      }

      .switch-container {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 86px;
        height: 42px;
        // background-color: rgba(53, 86, 145, 0.08);
        border-radius: 15px;
        padding: 4px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        &>* {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
          font-size: 12px;
          border-radius: 6px;
          border: 1px solid rgba(53, 86, 145, 0.5);
          background: #FFF;
          color: #355691;
          padding: 6px 16px;
          transition: all 300ms ease-in-out;

          &.active {
            background-color: #355691;
            color: #FFF;
            // border: 0px;
            border-color: #355691;
          }
        }
      }
    }
  }
}

// border-radius: 50%;
// background-color: #ffffff;
// border: 2px solid #333;
// cursor: pointer;
// &.active {
//   background: #355691;
//   border: 2px solid #333;
// }