.movement-layTime {
  width: 1450px;
  height: 750px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #FFF;
  overflow: hidden;

  :global {
    .layTime-header {
      width: 100%;
      height: 62px;
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 19px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.85);
      align-items: center;
      border-radius: 6px 6px 0px 0px;

      .fleet-title {
        font-size: 20px;

        &::before {
          height: 16px;
        }
      }

      .header-icon {
        width: 16px;
        height: 16px;
        display: flex;
        cursor: pointer;
      }
    }

    .layTime-container {
      width: 100%;
      display: flex;
      height: calc(100% - 126px);
      overflow: hidden;

      .layTime-leftArea {
        width: 153px;
        height: 100%;
        overflow: hidden auto;
        border-right: 1px solid #D9D9D9;
        background: #EFF1F5;
        padding: 8px;
      }

      .layTime-rightArea {
        width: calc(100% - 153px);
        height: 100%;
        display: flex;
        flex-direction: column;

        .rightArea {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 8px 21px 16px 12px;
          gap: 16px;
        }
      }
    }

    .layTime-footer {
      border-top: 1px solid rgba(217, 217, 217, 0.85);
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      padding: 0px 21px;
      border-radius: 0px 0px 6px 6px;
    }
  }
}