.voyagemanage-attachAndcontact {
  padding: 12px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FBFBFB;
  position: relative;

  :global {
    .attachAndcontact-title {
      color: var(--color-text-primary, #355691);
      font-size: 16px;
      font-weight: 590;
      line-height: 24px;
      padding-bottom: 8px;
      border-bottom: 1px solid var(--color-line-separator, #E7E9EC);
      /* 150% */
    }

    .attachAndcontact-upload {
      &>.ant-upload {
        margin-bottom: 0px;
      }
    }

    .attach-container {
      width: 100%;
      gap: 4px 8px;
      display: grid;
      grid-template-columns: repeat(auto-fit, 430.5px);
      // grid-template-columns: repeat(auto-fit, calc((100% - 8px)/2));
      grid-template-rows: repeat(auto-fit, 66px);

      .attach-item {
        border-radius: 6px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        gap: 8px;
        padding: 8px;
        align-items: center;
        display: flex;

        .attach-icon {
          font-size: 48px;
          border-radius: 6px;
          width: 48px;
          height: 48px;
        }

        .attach-name {
          color: #355691;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          flex-grow: 1;
        }

        .attach-actions {
          display: flex;
          gap: 8px;
          align-items: center;

          &>* {
            font-size: 16px;
            cursor: pointer;
          }
          .fleet-download {
            cursor: not-allowed;
          }
        }
      }
    }

    .contact-select-container {
      width: 150px;
    }

    .contact-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 283px);
      // grid-template-columns: repeat(auto-fit, calc((100% - 24px)/3));
      gap: 8px;
      // grid-template-rows: repeat(auto-fit, 140px);

      .contact-item {
        display: flex;
        flex-direction: column;
        gap: 6px;
        border-radius: 6px;
        border: 1px solid #D2DAE4;
        background: #FFF;

        .contactItem-header {
          width: 100%;
          display: flex;
          padding: 6px 12px;
          display: flex;
          justify-content: space-between;
          background: #EFF1F5;
          border-radius: 6px 6px 0px 0px;

          .header-contactType {
            gap: 8px;
            display: flex;
            align-items: center;

            &>:first-child {
              font-size: 20px;
            }

            &>:last-child {
              color: #355691;
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
            }
          }

          .header-actions {
            display: flex;
            gap: 8px;
            align-items: center;

            &>* {
              font-size: 16px;
              cursor: pointer;
            }
          }
        }

        .contactItem-container {
          width: 100%;
          padding: 4px 13px;
          flex-direction: column;
          gap: 8px;
          display: flex;

          &>* {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

  }
}