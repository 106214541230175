.movement-calculator {
	width: 100%;
	height: calc(100% - 103px);
	display: flex;
	flex-direction: column;
	gap: 12px;

	:global {
		.calculator-header {
			width: 100%;
			height: 32px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.fleet-title {
				font-size: 16px;

				&::before {
					width: 6px;
					height: 16px;
				}
			}

			.header-timeContainer {
				display: flex;
				gap: 20px;
				.header-datePicker {
					width: 240px;
				}
			}
		}

		.calculator-container {
			width: 100%;
			height: calc(100% - 44px);
			display: grid;
			gap: 9px;
			grid-template-columns: 429px calc(100% - 438px);
			grid-template-rows: repeat(auto-fit, 100%);

			.calculatorArea {
				display: flex;
				flex-direction: column;
				border-radius: 6px;
				border: 1px solid #d9d9d9;

				.calculatorArea-header {
					width: 100%;
					background: #eff1f5;
					border-radius: 6px 6px 0px 0px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.06);
					height: 78px;

					.calculatorItem {
						& > * {
							color: rgba(0, 0, 0, 0.85);
							font-size: 12px;
							font-weight: 450;
							line-height: 22px;
							text-align: center;
							padding: 8px;
							position: relative;
							border-bottom: 1px solid rgba(0, 0, 0, 0.06);

							&::after {
								background: rgba(0, 0, 0, 0.06);
								width: 1px;
								height: 22px;
								display: inline-block;
								position: absolute;
								right: 0px;
								content: "";
							}
						}
					}
				}

				.calculatorArea-container {
					width: 100%;
					border-radius: 0px 0px 6px 6px;
					height: calc(100% - 123px);
					overflow: hidden auto;
				}

				.calculatorArea-footer {
					width: 100%;
					height: 45px;
					border-top: 1px solid #d9d9d9;
					padding: 5px 12px;

					.summay-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 16px;
						font-weight: 500;
						line-height: normal;

						.summay-label {
							color: #355691;
						}

						.summay-value {
							color: #ff8632;

							&::after {
								color: #484848;
								content: "Days";
								display: inline-block;
								margin-left: 4px;
							}
						}
					}
				}
			}

			.calculator-leftArea {
				.calculatorArea-header {
					display: flex;

					.calculatorItem {
						width: calc(100% - 77px);
						height: 100%;
						display: grid;
						grid-template-columns: repeat(auto-fit, calc(100% / 2));
						grid-template-rows: repeat(auto-fit, calc(100% / 2));

						& > :nth-child(2) {
							&::after {
								display: none;
							}
						}

						& > :nth-child(3) {
							border-bottom: 0px;
						}

						& > :nth-child(4) {
							border-bottom: 0px;

							&::after {
								display: none;
							}
						}
					}

					.calculatorItem-actions {
						width: 77px;
						height: 100%;
						position: relative;
						display: flex;
						align-items: center;

						&::before {
							background: rgba(0, 0, 0, 0.06);
							width: 1px;
							height: 22px;
							display: inline-block;
							content: "";
							position: absolute;
							left: 0px;
						}
					}
				}

				.calculatorArea-container {
					.calculatorArea-scrollArea {
						width: 100%;
						display: flex;
						flex-direction: column;

						.calculatorItem {
							width: 100%;
							display: grid;
							grid-template-columns: 36px 150px 150px 77px;
							grid-template-rows: repeat(auto-fit, 39px);

							.calculatorItem-select {
								height: unset;
							}

							& > * {
								border-bottom: 1px solid rgba(0, 0, 0, 0.06);
								padding: 4px 8px;
								display: flex;
								justify-content: center;
								align-items: center;
								// color: var(--character-title-85, rgba(0, 0, 0, 0.85));
								color: rgba(0, 0, 0, 0.85);
								text-align: center;

								/* Body/regular */
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;

								.input-item {
									width: 100%;
									height: 30px;
								}

								/* 157.143% */
								input {
									min-width: unset;
								}
							}

							.calculatorItem-actions {
								height: 100%;
								display: flex;
								gap: 4px;

								& > * {
									width: 28px;
									height: 28px;
									cursor: pointer;
									display: flex;
								}
							}
						}
					}
				}

				.calculatorArea-footer {
					display: flex;
					align-items: center;

					.summay-item {
						width: 100%;
					}
				}
			}

			.calculator-rightArea {
				.calculatorArea-header {
					display: flex;

					// 160px 34px 151px 76px calc(113px / 3)
					.calculatorItem-row {
						width: calc(100% - 200px);
						height: 100%;
						display: grid;
						grid-template-columns: repeat(auto-fit, 100%);
						grid-template-rows: repeat(auto-fit, calc(100% / 2));

						& > :first-child.calculatorItem {
							display: grid;
							grid-template-columns: 218px 227px calc(100% - 445px);
							grid-template-rows: repeat(auto-fit, 100%);

							& > :last-child {
								&::after {
									display: none;
								}
							}
						}

						& > :last-child.calculatorItem {
							display: grid;
							grid-template-columns: 184px 34px 151px 76px 1fr 1fr 1fr;
							grid-template-rows: repeat(auto-fit, 100%);

							& > * {
								border-bottom: 0px;
							}

							& > :last-child {
								&::after {
									display: none;
								}
							}
						}
					}

					.calculatorItem-remark {
						height: 100%;
						width: 200px;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						// color: var(--character-title-85, rgba(0, 0, 0, 0.85));
						color: rgba(0, 0, 0, 0.85);
						font-size: 12px;
						font-weight: 450;
						line-height: 22px;

						/* 183.333% */
						&::before {
							background: rgba(0, 0, 0, 0.06);
							width: 1px;
							height: 22px;
							display: inline-block;
							content: "";
							position: absolute;
							left: 0px;
						}
					}
				}

				.calculatorArea-container {
					.calculatorArea-scrollArea {
						width: 100%;
						display: flex;
						flex-direction: column;

						.calculatorItem {
							width: 100%;
							display: grid;
							grid-template-columns: 184px 34px 151px 76px 1fr 1fr 1fr 200px;
							grid-template-rows: repeat(auto-fit, 39px);

							& > * {
								border-bottom: 1px solid rgba(0, 0, 0, 0.06);
								padding: 4px 8px;
								display: flex;
								justify-content: center;
								align-items: center;
								// color: var(--character-title-85, rgba(0, 0, 0, 0.85));
								color: rgba(0, 0, 0, 0.85);
								text-align: center;

								/* Body/regular */
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;

								.input-item {
									width: 100%;
									height: 30px;
								}

								/* 157.143% */
								input {
									min-width: unset;
								}
							}
							&.is-except {
								// background-color: rgba(53, 86, 145, 0.05);
								// background-color: rgba(0, 0, 0, 0.06);
                background-color: #eff1f5;
							}
						}
					}
				}

				.calculatorArea-footer {
					grid-template-columns: repeat(auto-fit, calc((100% - 40px) / 3));
					grid-template-rows: repeat(auto-fit, 100%);
					gap: 20px;
					display: grid;

					& > :last-child.summay-item {
						.summay-value {
							&::after {
								content: "USD";
							}
						}
					}
				}
			}
		}
	}
}
