.movement-charterBase--planList {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;

  :global {
    .planList-lines {
      width: 1px;
      height: 32px;
      background: rgba(0, 0, 0, 0.10);
    }

    .planList-estimation {
      display: flex;
      gap: 12px;
      align-items: center;
      width: calc(100% - 287px);

      &>.label {
        color: var(--Grey-100, #333);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        width:95px;
      }

      .planList-estimation--list {
        width: calc(100% - 103px);
        overflow: auto hidden;
        .planList-estimation--scrollArea {
          height: 100%;
          display: flex;
          gap: 12px;
          padding-bottom: 4px;
          &>.est-active {
            padding: 6px 16px;
            gap: 10px;
            display: flex;
            border-radius: 4px;
            background: #355691;
            color: #FFF;
            font-size: 12px;
            line-height: 20px;
            white-space: nowrap;
            &>:first-child {
              font-weight: 500;
            }
  
            &>:not(:first-child) {
              font-weight: 400;
              text-decoration-line: underline;
              cursor: pointer;
            }
          }
  
          &>.est--notActive {
            border-color: #d0d0d0;
            color: #d9d9d9;
          }
        }
        &::-webkit-scrollbar {
          width: 0px;
          height: 5px;
        }
      
        &::-webkit-scrollbar-corner {
          background-color: rgba(41, 42, 44, 1);
        }
      
        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: rgba(53, 86, 145, 0.18);
          border: 1px solid rgb(219, 219, 219);
          cursor: pointer;
        }
      
        &::-webkit-scrollbar-track {
          border-radius: 4px;
        }
      }
    }
    .planList-add {
      gap: 8px;
      display: flex;
      align-items: center;
    }
  }
}